import { infeasibilityDeltas } from './constants'

/* eslint-disable max-len */
export const returnErrors = (allShifts, allParticipants, slotSubsets, algorithmError, postChecks, t) => {
  const output = []
  const infeasible = []

  const formatDateString = date => date.toLocaleString(undefined, {
    day: '2-digit',
    month: 'long',
    timeZone: 'GMT',
  })

  const findSlot = slotId => formatDateString(new Date(allShifts?.shifts_needs?.find(slotToFind => slotToFind.id_slot == slotId)?.start))

  const findShiftNameById = id_shift => allShifts?.shifts?.find(e => e.id_shift == id_shift)?.name

  const findParticipantById = id => allParticipants?.itr_prefs?.find(participant => participant?.user.id == id)?.user

  const findRoleNameById = id => allParticipants?.roles?.find(role => role.id === id)?.name

  const findSlotSubsetNameById = id => Object.values(slotSubsets.slot_subsets)?.find(slotSubset => slotSubset.id_slot_subset == id)?.name

  const generateShiftErrorOutput = (shiftErrors, shiftName, isSurplus) => {
    if (shiftErrors) {
      const demandKey = isSurplus ? 'max_demand' : 'max_supply'
      const supplyKey = isSurplus ? 'min_supply' : 'min_demand'
      if (isSurplus) {
        output.push(t('manager.algorithmErrors.shiftSupplyDemandSurplus', { shiftName, maxDemand: shiftErrors[demandKey], minSupply: shiftErrors[supplyKey] }))
      } else {
        output.push(t('manager.algorithmErrors.shiftSupplyDemandDeficit', { shiftName, maxDemand: shiftErrors[demandKey], minSupply: shiftErrors[supplyKey] }))
      }
    }
  }

  const generateShiftUserPreassignedOSOutput = (shiftUserPreassignedOS, shiftName, participant) => {
    if (shiftUserPreassignedOS) {
      output.push(t('manager.algorithmErrors.shiftUserPreassignedOS', {
        shiftName, firstName: participant?.firstname, lastName: participant?.lastname, maxReq: shiftUserPreassignedOS.max_req, preassignedCnt: shiftUserPreassignedOS.preassigned_OS_cnt,
      }))
    }
  }

  const generateShiftUserPreassignedOSFreeDaysOutput = (shiftUserPreassignedOSFreeDays, shiftName, participant) => {
    if (shiftUserPreassignedOSFreeDays) {
      const date = findSlot(shiftUserPreassignedOSFreeDays.id_slot)
      // const date = new Date(allShifts?.shifts_needs?.find(slot => slot.id_slot == shiftUserPreassignedOSFreeDays.id_slot)?.start)
      const required_free_slots = shiftUserPreassignedOSFreeDays?.required_free_slots.map(slot => findSlot(slot)).join(t('generic.andWithSpaces'))
      // const required_free_slots = shiftUserPreassignedOSFreeDays?.required_free_slots.map(slot => formatDateString(new Date(allShifts?.shifts_needs.find(slotToFind => slotToFind.id_slot == slot).start))).join(t('generic.andWithSpaces'))
      output.push(t('manager.algorithmErrors.shiftUserPreassignedOSFreeDays', {
        shiftName, firstName: participant?.firstname, lastName: participant?.lastname, date, freeDays: required_free_slots,
      }))
    }
  }

  const generateSlotErrorOutput = slotErrors => {
    if (slotErrors) {
      // const date = new Date(allShifts?.shifts_needs?.find(slot => slot.id_slot == slotErrors?.id_slot)?.start)
      const date = findSlot(slotErrors?.id_slot)
      output.push(t('manager.algorithmErrors.slotSupplyDemandDeficit', {
        date, assignableUsers: slotErrors?.assignable_users, numPartakers: slotErrors?.num_partakers, preassignedOS: slotErrors?.preassigned_OS, slotMinDemand: slotErrors?.slot_min_demand,
      }))
    }
  }

  const generateUserErrorOutput = (userError, participant, slotSubsetName) => {
    if (userError) {
      output.push(t('manager.algorithmErrors.userMaxSubsetSlotExceeded', {
        firstName: participant?.firstname, lastName: participant?.lastname, maxSubsetSlot: userError?.max_subset_slot, slotSubsetName, preassignedOS: userError?.preassigned_OS_on_slotsubset,
      }))
    }
  }

  const generateUserPreassignedOSComplementary = (preassignedOSComplementaryError, participant, slotSubsetName) => {
    if (preassignedOSComplementaryError) {
      output.push(
        `${t('manager.algorithmErrors.userMinSubsetSlotNotMetIntro', {
          firstName: participant?.firstname,
          lastName: participant?.lastname,
          minSubsetSlot: preassignedOSComplementaryError?.subset_slot_min_req,
          slotSubsetName,
        })
        } ${t('manager.algorithmErrors.userMinSubsetSlotNotMetAssigned', {
          preassignedSubsetSlot: preassignedOSComplementaryError?.preassigned_OS_on_subset_slot,
          missingSubsetSlotShifts: preassignedOSComplementaryError?.missing_slot_subset_shifts_to_assign,
        })
        } ${t('manager.algorithmErrors.userMaxTotalRoleReqs', {
          totalMaxRoleReqs: preassignedOSComplementaryError?.total_max_role_reqs,
          totalPreassignedOS: preassignedOSComplementaryError?.total_preassigned_OS,
        })
        } ${t('manager.algorithmErrors.userPreassignedOSBreakdown', {
          preassignedSubsetSlotNot: preassignedOSComplementaryError?.preassigned_OS_not_on_subset_slot,
          preassignedSubsetSlot: preassignedOSComplementaryError?.preassigned_OS_on_subset_slot,
          slotSubsetName,
        })
        } ${t('manager.algorithmErrors.algorithmInsufficientShifts', {
          maxPossibleExtraShifts: preassignedOSComplementaryError?.max_possible_extra_shifts_to_assign,
          missingSubsetSlotShifts: preassignedOSComplementaryError?.missing_slot_subset_shifts_to_assign,
          slotSubsetName,
        })}`,
      )
    }
  }

  const generateUserSupplyDemandDeficit = (userSupplyDemandDeficit, participant) => {
    if (userSupplyDemandDeficit) {
      output.push(t('manager.algorithmErrors.userSupplyDemandDeficit', {
        firstName: participant?.firstname,
        lastName: participant?.lastname,
        minReqs: userSupplyDemandDeficit.min_reqs,
        numSlots: userSupplyDemandDeficit.num_slots,
        minAssociatedFreeDays: userSupplyDemandDeficit.min_associated_free_days,
        numOtherEvents: userSupplyDemandDeficit.num_other_events,
        numVacations: userSupplyDemandDeficit.num_vacations,
      }))
    }
  }

  if (algorithmError) {
    if (algorithmError.type === 'pre_exec') {
      const {
        errors, shifts, slots, users, incomp_groups, slot_subsets,
      } = algorithmError.context

      const itrSlotSubsetSupplyDemandSurplus = errors?.itr_slotsubset_supply_demand_surplus
      const itrSlotSubsetSupplyDemandDeficit = errors?.itr_slotsubset_supply_demand_deficit

      if (itrSlotSubsetSupplyDemandSurplus) {
        output.push(t('manager.algorithmErrors.weekendConfigSurplus', {
          maxDemand: itrSlotSubsetSupplyDemandSurplus?.max_demand,
          minSupply: itrSlotSubsetSupplyDemandSurplus?.min_supply,
        }))
      }

      if (itrSlotSubsetSupplyDemandDeficit) {
        output.push(t('manager.algorithmErrors.weekendConfigDeficit', {
          maxSupply: itrSlotSubsetSupplyDemandDeficit?.max_supply,
          minDemand: itrSlotSubsetSupplyDemandDeficit?.min_demand,
        }))
      }

      // shifts
      for (const shift in shifts) {
        if (Object.prototype.hasOwnProperty.call(shifts, shift)) {
          const {
            errors: shiftErrors, partakers, shift_slots,
          } = shifts[shift]
          const shiftName = findShiftNameById(shift)
          generateShiftErrorOutput(shiftErrors?.shift_supply_demand_deficit, shiftName, false)
          generateShiftErrorOutput(shiftErrors?.shift_supply_demand_surplus, shiftName, true)

          // partakers
          for (const partaker in partakers) {
            if (Object.prototype.hasOwnProperty.call(partakers, partaker)) {
              const {
                slots, errors,
              } = partakers[partaker]
              const participant = findParticipantById(partaker)
              generateShiftUserPreassignedOSOutput(errors?.shift_user_preassigned_OS_surplus, shiftName, participant)

              // slots
              for (const slot in slots) {
                if (Object.prototype.hasOwnProperty.call(slots, slot)) {
                  generateShiftUserPreassignedOSFreeDaysOutput(slots[slot]?.errors?.shift_user_preassigned_os_free_days, shiftName, participant)
                }
              }
            }
          }
          // shift_slots
          for (const shiftSlot in shift_slots) {
            if (Object.prototype.hasOwnProperty.call(shift_slots, shiftSlot)) {
              const shiftSlotPreassignedOS = shift_slots[shiftSlot].errors?.shift_slot_preassigned_OS
              const shiftSlotSupplyDemandDeficit = shift_slots[shiftSlot].errors?.shift_slot_supply_demand_deficit
              const date = findSlot(shiftSlot)
              // const date = new Date(allShifts?.shifts_needs?.find(slot => slot.id_slot == shiftSlotPreassignedOS.id_slot)?.start)
              if (shiftSlotPreassignedOS) {
                output.push(t('manager.algorithmErrors.shiftSectionExcess', {
                  shiftName,
                  date,
                  maxNeed: shiftSlotPreassignedOS?.max_need,
                  preassignedOSCnt: shiftSlotPreassignedOS?.preassigned_OS_cnt,
                }))
              }
              if (shiftSlotSupplyDemandDeficit) {
                output.push(t('manager.algorithmErrors.shiftSectionDeficit', {
                  shiftName,
                  date,
                  needsMin: shiftSlotSupplyDemandDeficit.needs_min,
                  qualifiedRoles: shiftSlotSupplyDemandDeficit.qualified_roles.map(role => findRoleNameById(role)).join(', '),
                  qualifiedUsers: shiftSlotSupplyDemandDeficit.qualified_users,
                  postFiltAvailUsers: shiftSlotSupplyDemandDeficit.post_filt_avail_users.length,
                  shiftPreassigned: shiftSlotSupplyDemandDeficit.shift_preassigned ? t('manager.algorithmErrors.shiftPreassigned', { shiftPreassigned: shiftSlotSupplyDemandDeficit.shift_preassigned }) : '',
                }))
              }
            }
          }
        }
      }

      // slots
      for (const slot in slots) {
        if (Object.prototype.hasOwnProperty.call(slots, slot)) {
          generateSlotErrorOutput(slots[slot]?.errors?.slot_supply_demand_deficit)
        }
      }

      // users
      for (const user in users) {
        if (Object.prototype.hasOwnProperty.call(users, user)) {
          const userSupplyDemandDeficit = users[user].errors?.user_supply_demand_deficit

          const participant = findParticipantById(user)
          generateUserSupplyDemandDeficit(userSupplyDemandDeficit, participant)

          for (const slot_subset in users[user].slot_subsets) {
            if (Object.prototype.hasOwnProperty.call(users[user].slot_subsets, slot_subset)) {
              const slotSubsetName = slotSubsets.slot_subsets[slot_subset]?.name
              const itrSlotSubsetUserPreassignedOSSurplus = users[user].slot_subsets[slot_subset].errors?.itr_slotsubset_user_preassigned_os_surplus
              const itrSlotSubsetUserPreassignedOSComplementary = users[user].slot_subsets[slot_subset].errors?.itr_slotsubset_user_preassigned_os_complementary_surplus
              generateUserErrorOutput(itrSlotSubsetUserPreassignedOSSurplus, participant, slotSubsetName)
              generateUserPreassignedOSComplementary(itrSlotSubsetUserPreassignedOSComplementary, participant, slotSubsetName)
            }
          }
        }
      }

      // incomp_group
      for (const incomp_group in incomp_groups) {
        if (Object.prototype.hasOwnProperty.call(incomp_groups, incomp_group)) {
          const incompPreassignedOs = incomp_groups[incomp_group].errors?.incomp_preassigned_os
          if (incompPreassignedOs) {
            const { max_users_per_slot, violating_slots, name } = incompPreassignedOs
            const violatingDetails = violating_slots.map(slotUsers => {
              let slotDetails = t('manager.algorithmErrors.incompHelper', { day: findSlot(slotUsers?.id_slot), numUsers: slotUsers?.users?.length })
              const userNames = slotUsers.users.map(user => {
                const participant = findParticipantById(user)
                return `${participant?.firstname} ${participant?.lastname}`
              }).join(', ')
              slotDetails += `${userNames})`
              return slotDetails
            }).join(', ')

            output.push(t('manager.algorithmErrors.incompGroupNotRespected', {
              name,
              maxUsersPerSlot: max_users_per_slot,
              violatingDetails,
            }))
          }
        }
      }

      // slot_subsets
      for (const slot_subset in slot_subsets) {
        if (Object.prototype.hasOwnProperty.call(slot_subsets, slot_subset)) {
          const slotSubsetName = slotSubsets.slot_subsets[slot_subset]?.name

          const slotSubsetSupplyDemandSurplus = slot_subsets[slot_subset].errors?.itr_slotsubset_supply_demand_surplus
          const slotSubsetSupplyDemandDeficit = slot_subsets[slot_subset].errors?.itr_slotsubset_supply_demand_deficit

          if (slotSubsetSupplyDemandSurplus) {
            output.push(t('manager.algorithmErrors.groupExcessConfig', {
              slotSubsetName,
              maxDemand: slotSubsetSupplyDemandSurplus.max_demand,
              minSupply: slotSubsetSupplyDemandSurplus.min_supply,
            }))
          }

          if (slotSubsetSupplyDemandDeficit) {
            output.push(t('manager.algorithmErrors.groupDeficitConfig', {
              slotSubsetName,
              minDemand: slotSubsetSupplyDemandDeficit.min_demand,
              maxSupply: slotSubsetSupplyDemandDeficit.max_supply,
            }))
          }
        }
      }
    } else if (algorithmError.type === 'infeasible') {
      output.push(t('manager.algorithmErrors.infeasibilitySolution'))
      algorithmError.context.forEach(error => {
        const positiveDeltas = error.positive_deltas.map(delta => infeasibilityDeltas[delta] || delta)
        const negativeDeltas = error.negative_deltas.map(delta => infeasibilityDeltas[delta] || delta)

        infeasible.push(positiveDeltas.length > 0 ? t('manager.algorithmErrors.infeasibilitySuggestionBoth', { positiveDeltas: positiveDeltas.join(t('generic.andWithSpaces')), negativeDeltas: negativeDeltas.join(t('generic.andWithSpaces')) }) : t('manager.algorithmErrors.infeasibilitySuggestionNegative', { negativeDeltas: negativeDeltas.join(t('generic.andWithSpaces')) }))
      })
    } else {
      output.push(t('manager.algorithmErrors.genericError'))
    }
  }

  if (postChecks) {
    const { shifts, users, incomp_groups } = postChecks

    // SHIFTS ERRORS
    if (shifts) {
      Object.keys(shifts).forEach(shift => {
        const { errors } = shifts[shift]

        // Check that enough people were assigned to fill the min_needs of a shift in a given slot
        const shiftNeedsDeficit = errors?.shift_needs_deficit
        const violationSlotsDeficit = shiftNeedsDeficit?.violation_slots.map(slot => findSlot(slot))

        if (shiftNeedsDeficit) {
          output.push(
            `${t('manager.algorithmErrors.shiftNotCovered', {
              shiftName: findShiftNameById(shift),
              days: violationSlotsDeficit.length > 1 ? t('generic.multipleDays') : t('generic.singleDay'),
            })} ${violationSlotsDeficit.join(t('generic.andWithSpaces'))}`,
          )
        }

        // Check that no more people were assigned to shift in a given slot than the max_need
        const shiftNeedsSurplus = errors?.shift_needs_surplus
        const violationSlotsSurplus = shiftNeedsSurplus?.violation_slots.map(slot => findSlot(slot))

        if (shiftNeedsSurplus) {
          output.push(
            `${t('manager.algorithmErrors.shiftSurplus', {
              shiftName: findShiftNameById(shift),
              days: violationSlotsSurplus.length > 1 ? t('generic.multipleDays') : t('generic.singleDay'),
            })} ${violationSlotsSurplus.join(t('generic.andWithSpaces'))}`,
          )
        }
      })
    }

    // USERS ERRORS
    if (users) {
      Object.keys(users).forEach(user => {
        const {
          errors, shifts, shift_clusters, slot_subsets,
        } = users[user]
        const participant = findParticipantById(user)
        const fullName = `${participant?.firstname} ${participant?.lastname}`

        // # Check that no shift was allocated during a user's `other event` or `vacation`
        const userFixedZeroAssignment = errors?.user_fixedzero_assignment
        const fixed0ViolationSlots = userFixedZeroAssignment?.violation_slots.map(slot => findSlot(slot))

        if (userFixedZeroAssignment) {
          output.push(
            t('manager.algorithmErrors.userAssignedDuringEventOrVacation', {
              fullName,
              days: `${fixed0ViolationSlots.length > 1 ? t('generic.multipleDays') : t('generic.singleDay')} ${fixed0ViolationSlots.join(t('generic.andWithSpaces'))}`,
            }),
          )
        }

        // # Check that a user is assigned to at most one shift per slot
        const userSlotMultipleAssignment = errors?.user_slot_multiple_assignment
        const multAssViolationSlots = userSlotMultipleAssignment?.violation_slots.map(slot => findSlot(slot))

        if (userSlotMultipleAssignment) {
          output.push(
            `${t('manager.algorithmErrors.userAssignedToMultipleSections', {
              fullName,
              days: multAssViolationSlots.length > 1 ? t('generic.multipleDays') : t('generic.singleDay'),
            })} ${multAssViolationSlots.join(t('generic.andWithSpaces'))}`,
          )
        }

        // # Check that a user has more than min_subset_slot_1 days of shift
        const userslotSubsetDeficit = errors?.user_slotSubset_deficit

        if (userslotSubsetDeficit) {
          output.push(
            t('manager.algorithmErrors.userNotMeetingMinSubsetSlot', {
              fullName,
              assignedSubsetSlots: userslotSubsetDeficit.assigned_subset_slots,
              minSubset: userslotSubsetDeficit.min_subset,
            }),
          )
        }

        // # Check that a user has less than max_subset_slot_1 days of shift
        const userslotSubsetSurplus = errors?.user_slotSubset_surplus

        if (userslotSubsetSurplus) {
          output.push(
            t('manager.algorithmErrors.userExceedingMaxSubsetSlot', {
              fullName,
              assignedSubsetSlots: userslotSubsetSurplus.assigned_subset_slots,
              maxSubset: userslotSubsetSurplus.max_subset,
            }),
          )
        }

        // # Check all preassigned shifts for the user have been followed. (TODO)
        const userPreassignedShifts = errors?.user_preassigned_shifts

        if (userPreassignedShifts) {
          output.push(
            t('manager.algorithmErrors.userPreassignedShiftNotFollowed', {
              fullName,
              details: userPreassignedShifts.violating_coords.map(subArray => t('manager.algorithmErrors.shiftPreassignedOnDay', {
                shiftName: findShiftNameById(subArray[0]),
                day: findSlot(subArray[1]),
              })).join(t('generic.andWithSpaces')),
            }),
          )
        }

        // # Check that all free days have been followed for the user for all assigned slots
        const userFreeDays = errors?.user_free_days
        if (userFreeDays) {
          output.push(
            t('manager.algorithmErrors.userFreeDaysNotRespected', {
              fullName,
              details: userFreeDays.violating_coords.map(subArray => t('manager.algorithmErrors.shiftPreassignedOnDay', {
                shiftName: findShiftNameById(subArray[0]),
                day: findSlot(subArray[1]),
              })).join(t('generic.andWithSpaces')),
            }),
          )
        }

        const userOtherEvents = errors?.user_other_events
        if (userOtherEvents) {
          output.push(
            t('manager.algorithmErrors.userAssignedDuringOtherEvent', {
              fullName,
              days: userOtherEvents.violating_slots.map(slot => findSlot(slot)).join(t('generic.andWithSpaces')),
            }),
          )
        }

        const userVacations = errors?.user_vacations
        if (userVacations) {
          output.push(
            t('manager.algorithmErrors.userAssignedDuringVacation', {
              fullName,
              days: userVacations.violating_slots.map(slot => findSlot(slot)).join(t('generic.andWithSpaces')),
            }),
          )
        }

        const userDoubles = errors?.user_doubles
        if (userDoubles) {
          output.push(
            t('manager.algorithmErrors.userAssignedDoubleShifts', {
              fullName,
              count: userDoubles.violating_doubles.length,
              days: userDoubles.violating_doubles.map(slot => findSlot(slot)).join(t('generic.andWithSpaces')),
            }),
          )
        }

        const userTriples = errors?.user_triples
        if (userTriples) {
          output.push(
            t('manager.algorithmErrors.userAssignedTripleShifts', {
              fullName,
              count: userTriples.violating_triples.length,
              days: userTriples.violating_triples.map(slot => findSlot(slot)).join(t('generic.andWithSpaces')),
            }),
          )
        }

        Object.keys(shifts).forEach(shift => {
          const { errors } = shifts[shift]

          const userShiftRoleReqsDeficit = errors?.user_shift_rolereqs_deficit
          const userShiftRoleReqsSurplus = errors?.user_shift_rolereqs_surplus
          const shiftName = findShiftNameById(shift)
          if (userShiftRoleReqsDeficit) {
            output.push(
              t('manager.algorithmErrors.userNotMeetingShiftRoleReqs', {
                fullName,
                shiftName,
                missingShifts: userShiftRoleReqsDeficit.min_req - userShiftRoleReqsDeficit.assigned_shifts,
              }),
            )
          }

          if (userShiftRoleReqsSurplus) {
            output.push(
              t('manager.algorithmErrors.userExceedingShiftRoleReqs', {
                fullName,
                shiftName,
                exceedingShifts: userShiftRoleReqsSurplus.assigned_shifts - userShiftRoleReqsSurplus.max_req,
              }),
            )
          }
        })

        if (shift_clusters) {
          Object.keys(shift_clusters).forEach(cluster => {
            const { errors } = shift_clusters[cluster]
            const userClusterRoleReqsDeficit = errors?.user_cluster_rolereqs_deficit
            const userClusterRoleReqsSurplus = errors?.user_cluster_rolereqs_surplus
            if (userClusterRoleReqsDeficit) {
              output.push(
                t('manager.algorithmErrors.userNotMeetingClusterRoleReqs', {
                  fullName,
                  shiftNames: userClusterRoleReqsDeficit.id_shifts.map(shift => findShiftNameById(shift)).join(', '),
                  minReq: userClusterRoleReqsDeficit.min_req,
                  numShiftsInCluster: userClusterRoleReqsDeficit.num_shifts_in_cluster,
                  missingShifts: userClusterRoleReqsDeficit.min_req - userClusterRoleReqsDeficit.num_shifts_in_cluster,
                }),
              )
            }

            if (userClusterRoleReqsSurplus) {
              output.push(
                t('manager.algorithmErrors.userExceedingClusterRoleReqs', {
                  fullName,
                  shiftNames: userClusterRoleReqsSurplus.id_shifts.map(shift => findShiftNameById(shift)).join(', '),
                  maxReq: userClusterRoleReqsSurplus.max_req,
                  numShiftsInCluster: userClusterRoleReqsSurplus.num_shifts_in_cluster,
                  exceedingShifts: userClusterRoleReqsSurplus.num_shifts_in_cluster - userClusterRoleReqsSurplus.max_req,
                }),
              )
            }
          })
        }

        if (slot_subsets) {
          Object.keys(slot_subsets).forEach(slot_subset => {
            const { errors } = slot_subsets[slot_subset]

            const userSlotSubsetDeficit = errors?.user_slotsubset_deficit
            const userSlotSubsetSurplus = errors?.user_slotsubset_surplus
            const slotSubsetName = findSlotSubsetNameById(slot_subset)

            // # Check that a user has more than min_subset_slot_1 days of shift
            if (userSlotSubsetDeficit) {
              output.push(`El participante ${fullName} no tiene suficientes guardias en el grupo especial ${slotSubsetName}. Sólo tiene ${userSlotSubsetDeficit.assigned_subset_slots} y debería tener ${userSlotSubsetDeficit.min_subset}`)
            }
            if (userSlotSubsetDeficit) {
              output.push(
                t('manager.algorithmErrors.userSlotSubsetDeficit', {
                  fullName,
                  slotSubsetName,
                  assignedSubsetSlots: userSlotSubsetDeficit.assigned_subset_slots,
                  minSubset: userSlotSubsetDeficit.min_subset,
                }),
              )
            }
            // # Check that a user has less than max_subset_slot_1 days of shift
            if (userSlotSubsetSurplus) {
              output.push(
                t('manager.algorithmErrors.userSlotSubsetSurplus', {
                  fullName,
                  slotSubsetName,
                  assignedSubsetSlots: userSlotSubsetSurplus.assigned_subset_slots,
                  maxSubset: userSlotSubsetSurplus.max_subset,
                }),
              )
            }
          })
        }
      })
    }

    // INCOMP GROUP ERRORS
    if (incomp_groups) {
      Object.keys(incomp_groups).forEach(incompGroup => {
        const { errors } = incomp_groups[incompGroup]
        const incompGroupError = errors?.incomp_group
        const crossGroupIncompError = errors?.cross_group_incomp

        if (incompGroupError) {
          const { max_users_per_slot, violating_slots, name } = incompGroupError
          const violatingDetails = violating_slots.map(slotUsers => {
            let slotDetails = `${t('manager.algorithmErrors.incompHelper', { day: findSlot(slotUsers?.id_slot), numUsers: slotUsers?.users?.length })} (`
            const userNames = slotUsers.users.map(user => {
              const participant = findParticipantById(user)
              return `${participant?.firstname} ${participant?.lastname}`
            }).join(', ')
            slotDetails += `${userNames})`
            return slotDetails
          }).join(', ')
          output.push(
            t('manager.algorithmErrors.incompGroupError', {
              name,
              maxUsersPerSlot: max_users_per_slot,
              details: violatingDetails,
            }),
          )
        }

        if (crossGroupIncompError) {
          const { violating_slots, name } = crossGroupIncompError
          const violatingDetails = violating_slots.map(slotUsers => {
            const slotName = findSlot(slotUsers?.id_slot)

            const group1Users = slotUsers.group_1_users.map(user => {
              const participant = findParticipantById(user)
              return `${participant?.firstname} ${participant?.lastname}`
            }).join(', ')

            const group2Users = slotUsers.group_2_users.map(user => {
              const participant = findParticipantById(user)
              return `${participant?.firstname} ${participant?.lastname}`
            }).join(', ')
            return t('manager.algorithmErrors.crossGroupIncompHelper', {
              slotName, group1UsersLength: slotUsers?.group_1_users?.length, group1Users, group2UsersLength: slotUsers?.group_2_users?.length, group2Users,
            })
          }).join(', ')
          output.push(t('manager.algorithmErrors.crossGroupIncompError', { name, details: violatingDetails }))
        }
      })
    }
  }
  return { output, infeasible }
}
