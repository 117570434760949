import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from './Container'

export default function PrimaryFeatures({ setOpen }) {
  const [tabOrientation, setTabOrientation] = useState('horizontal')
  const { i18n } = useTranslation()
  const locale = i18n.language.includes('en') ? 'en' : 'es'

  const features = [
    {
      id: 'item1',
      image: `https://youshift-docs.s3.eu-west-3.amazonaws.com/config-${locale}.png`,
    },
    {
      id: 'item2',
      image: `https://youshift-docs.s3.eu-west-3.amazonaws.com/preferences-${locale}.png`,
    },
    {
      id: 'item4',
      image: `https://youshift-docs.s3.eu-west-3.amazonaws.com/results-${locale}.png`,
    },
    {
      id: 'item3',
      image: `https://youshift-docs.s3.eu-west-3.amazonaws.com/exchange-${locale}.png`,
    },
  ]

  useEffect(() => {
    const lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  // Use useTranslation hook to access translations
  const { t } = useTranslation()

  return (
    <>
      {/* WEB VERSION */}
      <section
        id="features"
        className="hidden lg:block relative overflow-hidden bg-gradient-to-b from-blue-600 to-teal-500 pt-20 pb-20 sm:py-28"
      >
        <Container className="relative">
          <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
            <h2 className="font-display text-4xl tracking-tight text-white sm:text-4xl md:text-5xl font-medium">
              {t('landing.primaryFeatures.title')}
            </h2>
            <p className="mt-6 text-2xl tracking-tight text-blue-100 font-medium">
              {t('landing.primaryFeatures.subtitle')}
            </p>
          </div>
          <Tab.Group
            as="div"
            className="grid grid-cols-1 items-center gap-y-2 pt-6 sm:gap-y-6 md:mt-12 lg:grid-cols-12 lg:pt-0"
            vertical={tabOrientation === 'vertical'}
          >
            {({ selectedIndex }) => (
              <>
                <div className="mx-auto flex max-[1023px]:max-w-[95%] overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                  <Tab.List className="relative z-10 flex flex-col gap-x-4 px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 whitespace-normal">
                    {features.map((feature, featureIndex) => (
                      <div
                        key={t(`landing.primaryFeatures.${feature.id}.title`)}
                        className={clsx(
                          'group relative lg:rounded-full rounded-md py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6',
                          selectedIndex === featureIndex
                            ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                            : 'hover:bg-white/10 lg:hover:bg-white/5',
                        )}
                      >
                        <h3>
                          <Tab
                            className={clsx(
                              'font-display text-left text-lg [&:not(:focus-visible)]:focus:outline-none',
                              selectedIndex === featureIndex
                                ? 'text-blue-600 lg:text-white'
                                : 'text-blue-100 hover:text-white lg:text-white',
                            )}
                          >
                            <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                            {t(`landing.primaryFeatures.${feature.id}.title`)}
                          </Tab>
                        </h3>
                        <p
                          className={clsx(
                            'mt-2 hidden text-sm lg:block',
                            selectedIndex === featureIndex
                              ? 'text-white'
                              : 'text-blue-100 group-hover:text-white',
                          )}
                        >
                          {t(`landing.primaryFeatures.${feature.id}.description`)}
                        </p>
                      </div>
                    ))}
                  </Tab.List>
                </div>
                <Tab.Panels className="lg:col-span-7">
                  {features.map(feature => (
                    <Tab.Panel key={t(`landing.primaryFeatures.${feature.id}.title`)} unmount={false}>
                      <div className="relative sm:px-6 lg:hidden">
                        <div className="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10 sm:inset-x-0 sm:rounded-t-xl" />
                        <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center">
                          {t(`landing.primaryFeatures.${feature.id}.description`)}
                        </p>
                      </div>
                      <div className="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
                        <img
                          className="w-full"
                          src={feature.image}
                          alt=""
                          sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                        />
                      </div>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </>
            )}
          </Tab.Group>
          <div className="px-6 sm:px-6 mt-12 lg:mt-24 lg:px-8">
            <div className="mx-auto max-w-2xl text-center border-gray-500">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {t('landing.primaryFeatures.allFeatures')}
              </h2>
              {/* <p className="mx-auto mt-6 max-w-xl text-xl leading-8 text-white">
                ¡Descubre todo lo que podemos hacer por ti!
              </p> */}
              <div className="mt-6 flex items-center justify-center gap-x-6">
                <button
                  onClick={() => setOpen(true)}
                  className="rounded-md bg-white px-3.5 py-2.5 text-md font-semibold text-blue-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {t('landing.primaryFeatures.learnMore')}
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* MOBILE VERSION */}
      <section
        id="features"
        className="lg:hidden relative overflow-hidden bg-gradient-to-b from-blue-600 to-teal-500 pt-20 pb-20 sm:py-28"
      >
        <Container className="relative">
          <div className="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
            <h2 className="ml-4 font-display text-4xl md:text-5xl tracking-tight text-white font-medium">
              {t('landing.primaryFeatures.title')}
            </h2>
          </div>
          <div
            className="grid grid-cols-1 items-center justify-items-center gap-y-2 pt-6"
          >
            <div className="mx-auto flex max-[1023px]:max-w-[95%] overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
              <div className="relative z-10 flex flex-col gap-x-4 px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 whitespace-normal">
                {features.map(feature => (
                  <div
                    key={t(`landing.primaryFeatures.${feature.id}.title`)}
                    className="group relative rounded-md py-3 px-4"
                  >
                    <h3 className="text-2xl md:text-3xl font-semibold text-white">
                      <span className="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                      {t(`landing.primaryFeatures.${feature.id}.title`)}
                    </h3>
                    <p
                      className="text-base md:text-lg lg:block text-white py-3 rounded-lg mx-auto"
                    >
                      {t(`landing.primaryFeatures.${feature.id}.description`)}
                    </p>
                    <div className="my-6 overflow-hidden rounded-lg bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto">
                      <img
                        className="w-full"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="px-6 sm:px-6 mt-12 lg:mt-24 lg:px-8">
            <div className="mx-auto max-w-2xl text-center border-gray-500">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                {t('landing.primaryFeatures.allFeatures')}
              </h2>
              {/* <p className="mx-auto mt-6 max-w-xl text-xl leading-8 text-white">
                ¡Descubre todo lo que podemos hacer por ti!
              </p> */}
              <div className="mt-6 flex items-center justify-center gap-x-6">
                <button
                  onClick={() => setOpen(true)}
                  className="rounded-md bg-white px-3.5 py-2.5 text-md font-semibold text-blue-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {t('landing.primaryFeatures.learnMore')}
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
